import React from "react"
import * as styles from "./serviceHero.module.scss"

const ServiceTabs = ({
  services,
  selectedServiceIndex,
  setSelectedService,
}) => {
  return (
    <div className={styles.tabs}>
      {services.map((service, i) => {
        const { name } = service.elements
        if (selectedServiceIndex === i) {
          return (
            <button
              onClick={() => setSelectedService(i)}
              key={service.id}
              className={styles.activeTab}
            >
              {name.value}
            </button>
          )
        } else
          return (
            <button
              onClick={() => setSelectedService(i)}
              key={service.id}
              className={styles.tab}
            >
              {name.value}
            </button>
          )
      })}
    </div>
  )
}

export default ServiceTabs
