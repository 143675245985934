import React from "react"
import { Link } from "gatsby"
import * as styles from "./serviceHero.module.scss"
import { FaChevronRight } from "react-icons/fa"
import ServiceTabs from "./ServiceTabs"
const ServiceTile = ({
  service,
  services,
  phoneNumber,
  i,
  selectedServiceIndex,
  setSelectedService,
}) => {
  const {
    heading,
    subheading,
    tagline,
    button,
    short_description,
  } = service.elements
  const { button_text, linked_page } = button.value[0].elements
  const { slug } = linked_page.value[0].elements
  if (selectedServiceIndex !== i) return null
  return (
    <div className={styles.bannerContent}>
      <ServiceTabs
        services={services.value}
        selectedServiceIndex={selectedServiceIndex}
        setSelectedService={setSelectedService}
        className={styles.tabs}
      />
      <span className={styles.heading}>{heading.value} </span>
      <h1 className={styles.subheading}>{subheading.value}</h1>
      <p>{tagline.value}</p>

      {slug.value !== "undefined" ? (
        <Link to={slug.value} className="primary-button">
          <span className={styles.linkSpan}>
            {button_text.value}
            <FaChevronRight className={styles.chevron} />
          </span>
        </Link>
      ) : null}
      <span className={styles.small}>{short_description.value}</span>
      <a href={`tel: ${phoneNumber}`} className={styles.callButton}>
        <span>{phoneNumber}</span>
      </a>
    </div>
  )
}

export default ServiceTile
