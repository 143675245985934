// extracted by mini-css-extract-plugin
export var activeTab = "serviceHero-module--active-tab--5suII";
export var additionalImage = "serviceHero-module--additional-image--7Kdqg";
export var bannerContent = "serviceHero-module--banner-content--1Zhf8";
export var callButton = "serviceHero-module--call-button--6WRXp";
export var chevron = "serviceHero-module--chevron--37fCs";
export var heading = "serviceHero-module--heading--6byqc";
export var hero = "serviceHero-module--hero--13MFW";
export var linkSpan = "serviceHero-module--link-span--7vvtW";
export var noSecondary = "serviceHero-module--no-secondary--2jiut";
export var small = "serviceHero-module--small--1oFRu";
export var subheading = "serviceHero-module--subheading--2WuId";
export var tab = "serviceHero-module--tab--2RUJP";
export var tabs = "serviceHero-module--tabs--6lf6j";