import React from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import * as styles from "./hero.module.scss"
const Hero = ({ banner, customCTA }) => {
  const {
    mobile,
    desktop,
    secondary_image,
    heading,
    subheading,
    buttons,
    tagline,
  } = banner

  const bgOverlay = "PrimaryColor"

  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]

  
  return (
    <BgImg
      fluid={sources}
      alt={desktop.value[0].description}
      className={styles.hero}
    >
      <div
        className={`${bgOverlay}Bg ${styles.opacity}`}
        id="hero-override"
      ></div>
      <div className={`container ${styles.bannerContent}`}>
        <h1>{heading.value}</h1>
        <RichTextElement value={subheading.value} />
        <div className={styles.homePageBannerLinks}>
          {buttons.value.map((button, i) => {
            const { button_text, linked_page } = button.elements
            const { slug } = linked_page.value[0].elements
            let className = "primary-button"
            if (i === 1) className = "white-accent-ghost-button"
            return (
              <Link key={button.id} to={slug.value} className={className}>
                {button_text.value}
              </Link>
            )
          })}
        </div>
        <div>
          {tagline.value ? (
            <p className={styles.smallText}>{tagline.value}</p>
          ) : (
            <p className={styles.smallText}>{customCTA}</p>
          )}
        </div>
      </div>
      {secondary_image.value.length > 0 && (
        <Img
          fluid={secondary_image.value[0].fluid}
          alt={secondary_image.value[0].description}
          className={styles.secondary}
        />
      )}
    </BgImg>
  )
}

export default Hero
